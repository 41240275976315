<template>
    <div class="d-flex flex-wrap justify-content-around">
        <a 
                class="btn text-white pr-3 pl-3 m-2" 
                href="https://www.malt.fr/profile/yanntachier" 
                role="button"
                style="background-color: #FF4967;"
                target="_blank">
            <font-awesome-icon :icon="['fas', 'external-link-alt']" class="mr-2"/>Malt
        </a>
        <a 
                class="btn text-white pr-3 pl-3 m-2" 
                href="https://www.codeur.com/-yanntac" 
                role="button"
                style="background-color: #2169ec;"
                target="_blank">
            <font-awesome-icon :icon="['fab', 'cuttlefish']" class="mr-2"/>Codeur.com
        </a>
        <a 
                class="btn text-white pr-3 pl-3 m-2" 
                href="https://www.lehibou.com/freelance/undefined/33283" 
                role="button"
                style="background-color: #FF5A5F;"
                target="_blank">
            <font-awesome-icon :icon="['fab', 'earlybirds']" class="mr-2"/>Le Hibou
        </a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>