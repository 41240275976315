<template>
    <footer class="bg-yaaann row sticky-bottom">
        <div class="container">
            <p class="m-2 text-center text-wrap"><small>yaaann 2020 - site réalisé avec <a href="https://cli.vuejs.org/" class="text-white"><u>Vue CLI</u></a></small></p>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>