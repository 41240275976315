<template>
    <div>
        <h1 class="h2 text-center d-none d-md-block">Mes compétences</h1>
		<hr>
		<ul class="list-group list-group-flush">
			<li class="list-group-item skill-cat list-group-item-dark">
				CONCEPTION D'APPLICATION
			</li>
			<ul class="list-group list-group-flush">
				<li class="list-group-item">
					Mettre en œuvre une <strong>méthode d'analyse et de spécification</strong> adaptée à l'application en cours d'élaboration.
				</li>
				<li class="list-group-item">Concevoir une <strong>base de données</strong>.</li>
				<li class="list-group-item">
					Appréhender les méthodes et outils pour la modélisation de problèmes réels.
				</li>
			</ul>
			<li class="list-group-item skill-cat list-group-item-dark">
				DEVELOPPEMENT D'APPLICATION
			</li>
			<ul class="list-group list-group-flush">
				<li class="list-group-item">Programmer une application en <strong>POO</strong></li>
				<li class="list-group-item">Implémenter une <strong>base de données</strong>.</li>
				<li class="list-group-item">Utiliser l’outil de gestion de versions <strong>Git</strong></li>
			</ul>
			<li class="list-group-item skill-cat list-group-item-dark">
				DEVELOPPEMENT WEB
			</li>
			<ul class="list-group list-group-flush">
				<li class="list-group-item">Construire une application Web avec <strong>Symfony</strong></li>			
				<li class="list-group-item">Développer une application en <strong>JavaScript</strong> (JQuery, Node.js et Vue.js)</li>
				<li class="list-group-item">Mettre en place et se connecter à une <strong>API REST</strong></li>
				<li class="list-group-item">Construire un site web <strong>Responsive</strong></li>
				<li class="list-group-item">Déployer un site avec <strong>WordPress</strong></li>			
				<li class="list-group-item"><strong>SEO</strong></li>
			</ul>
		</ul>
		
    </div>
</template>

<script>
export default {

}
</script>

<style>
.skill-cat{
	letter-spacing: .25rem;
	font-weight: bold;
}

</style>