const state = {
    contactApiKey: '69c2374309a9e0e3f3f169c74ff683',
/*     contactApiKey: 'ba3164d9bce7236e098ebed2a44d20' // For local tests
 */}

const getters = {
    contactApiKey(state) {
        return state.contactApiKey;
    }
}

export default{
    state,
    getters
}